import FormNavigation from 'components/FormNavigation';
import React, { useEffect, useState } from 'react';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { YourNameInputLabel } from 'components/LoanForm/YourName/YourName';

import { ReactComponent as BlackCard } from 'images/cards/black-card-front.svg';
import { ReactComponent as BlackCardBack } from 'images/cards/black-card-back.svg';
import { ReactComponent as BlueCard } from 'images/cards/blue-card-front.svg';
import { ReactComponent as BlueCardBack } from 'images/cards/blue-card-back.svg';
import { ReactComponent as WhiteCard } from 'images/cards/white-card-front.svg';
import { ReactComponent as WhiteCardBack } from 'images/cards/white-card-back.svg';

import { ReactComponent as PurpleSilverCard } from 'images/cards/purple-silver-card-front.svg';
import { ReactComponent as PurpleSilverCardBack } from 'images/cards/purple-silver-card-back.svg';
import { ReactComponent as LightPurpleCard } from 'images/cards/light-purple-card-front.svg';
import { ReactComponent as LightPurpleCardBack } from 'images/cards/light-purple-card-back.svg';
import { ReactComponent as GoldCard } from 'images/cards/gold-card-front.svg';
import { ReactComponent as GoldCardBack } from 'images/cards/gold-card-back.svg';

import { ReactComponent as CardFlipEllipse } from 'images/card-flip-ellipse.svg';
import { ReactComponent as HandPointing } from 'images/hand-pointing.svg';

import clsx from 'clsx';

import { useDispatch, useSelector } from 'react-redux';
import { setAboutYouData } from 'handlers/yourName';
import { useForm } from 'react-hook-form';
import { getYourNameData } from 'selectors/yourName';
import { AboutYouVariable } from 'enums/LoanFormVariables';
import { getMessageForRequiredFields } from 'utils/errors';
import useLayoutTheme from 'hooks/useLayoutTheme';
import { Themes } from 'styles/themes/ThemeTypes';
import FormContainer from 'components/LoanForm/FormContainer/FormContainer';
import useRotateCard from 'hooks/useRotateCard';
import { getCardData } from 'selectors/getCardData';
import { setCardData } from 'handlers/cardData';
import { FlowComponentType } from 'routes/FlowRouter';
import { useQueryParams } from 'hooks/useQueryParam';

import styles from './Customize.module.scss';

export enum CardColor {
  Black = 'black',
  Blue = 'blue',
  White = 'white',
  PurpleSilver = 'purple_silver',
  Gold = 'gold',
  LightPurple = 'light_purple',
}

interface Card {
  name: string;
  theme: Themes;
  front: JSX.Element;
  back: JSX.Element;
}

const cards: Record<CardColor, Card> = {
  [CardColor.Black]: { name: 'Black', theme: Themes.BLUE, front: <BlackCard />, back: <BlackCardBack /> },
  [CardColor.Blue]: { name: 'Blue', theme: Themes.DEFAULT, front: <BlueCard />, back: <BlueCardBack /> },
  [CardColor.White]: { name: 'White', theme: Themes.DARK, front: <WhiteCard />, back: <WhiteCardBack /> },
  [CardColor.PurpleSilver]: {
    name: 'Silver',
    theme: Themes.PURPLE,
    front: <PurpleSilverCard />,
    back: <PurpleSilverCardBack />,
  },
  [CardColor.Gold]: { name: 'Gold', theme: Themes.PURPLE, front: <GoldCard />, back: <GoldCardBack /> },
  [CardColor.LightPurple]: {
    name: 'Purple',
    theme: Themes.DEFAULT,
    front: <LightPurpleCard />,
    back: <LightPurpleCardBack />,
  },
} as const;

const colorSets = {
  '1': [CardColor.Black, CardColor.Blue, CardColor.White],
  '2': [CardColor.PurpleSilver, CardColor.Gold, CardColor.LightPurple],
};

const Customize = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const params = useQueryParams();
  const colorSet = colorSets[params.get('cardVersion') as keyof typeof colorSets] || colorSets[1];

  const dispatch = useDispatch();

  const { cardColor } = useSelector(getCardData);
  const [selectedColor, setSelectedColor] = useState(cardColor ?? colorSet[0]);
  const { theme, setTheme } = useLayoutTheme();

  const { cardRef: flipCardRef } = useRotateCard();

  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);

  const {
    register,
    trigger,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      first_name: firstName,
      last_name: lastName,
    },
  });

  const watcher = watch();

  const onCardClick = (card: CardColor) => {
    setSelectedColor(card);
    setTheme(cards[card].theme);
    analytics.track('Card Color Selected', { color: selectedColor });
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as AboutYouVariable, event.target.value.trim());
    trigger(event.target.name as AboutYouVariable);
  };

  const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
    setValue(event.target.name as AboutYouVariable, event.target.value);
    trigger(event.target.name as AboutYouVariable);
  };

  const onNext = () => {
    dispatch(setAboutYouData(watcher));
    dispatch(setCardData({ cardColor: selectedColor }));
    handleNext();
  };

  useEffect(() => {
    register(AboutYouVariable.FirstName, { required: getMessageForRequiredFields(YourNameInputLabel.FirstName) });
    register(AboutYouVariable.LastName, { required: getMessageForRequiredFields(YourNameInputLabel.LastName) });
  }, []);

  const selectedCard = cards[selectedColor!];

  useEffect(() => {
    setTheme(selectedCard.theme);
  }, []);

  return (
    <div className={styles.container}>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="Customize Your Card">
        <div className={styles.container}>
          <div className={styles.inputs}>
            <Input
              inputClassName={styles.input}
              errorMessage={errors[AboutYouVariable.FirstName]?.message}
              label="First name"
              placeholder="First Name"
              name={AboutYouVariable.FirstName}
              onChange={onChange}
              onBlur={onBlur}
              value={watcher[AboutYouVariable.FirstName]}
              data-neuro-label="firstName"
            />
            <Input
              inputClassName={styles.input}
              errorMessage={errors[AboutYouVariable.LastName]?.message}
              label="Last name"
              placeholder="Last Name"
              name={AboutYouVariable.LastName}
              onChange={onChange}
              onBlur={onBlur}
              value={watcher[AboutYouVariable.LastName]}
              data-neuro-label="lastName"
            />
          </div>

          <div className={styles.cardPickerContainer}>
            <p className={styles.label}>Select color</p>
            <div className={styles.cardPickerItems}>
              {Object.values(colorSet).map((color, index) => (
                <div
                  key={`${color}-${index}`}
                  className={clsx(styles.cardItem, styles[`cardItem__${theme}`])}
                  onClick={() => onCardClick(color)}
                >
                  <div
                    className={clsx(styles.card, styles[`card__${theme}`], {
                      [styles.selected]: selectedColor === color,
                    })}
                  >
                    {cards[color].front}
                  </div>
                  <p className={styles.cardColor}>{cards[color].name}</p>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.previewContainer}>
            <div className={styles.cardPreview}>
              <div className={styles.flipCardBackground} />
              <div className={clsx(styles.ellipseContainer, styles[theme])}>
                <CardFlipEllipse className={styles.ellipse} />
                <div className={styles.handPointingContainer}>
                  <HandPointing className={styles.handPointing} />
                </div>
              </div>
              <div className={styles.flipCard}>
                <div ref={flipCardRef} className={clsx(styles.flipCardInner)}>
                  <div className={styles.flipCardFront}>
                    {selectedCard.front}
                    <p className={clsx(styles.cardOwnerName, styles[selectedColor.toLowerCase()], styles.frontName)}>
                      {watcher.first_name} {watcher.last_name}
                    </p>
                  </div>
                  <div className={styles.flipCardBack}>
                    {selectedCard.back}
                    <p className={clsx(styles.cardOwnerName, styles[selectedColor.toLowerCase()], styles.backName)}>
                      {watcher.first_name} {watcher.last_name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Button disabled={!isValid} onClick={onNext} className={styles.button}>
            Next
          </Button>
        </div>
      </FormContainer>
    </div>
  );
};

export default Customize;
