import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LoadingSpinner } from 'images/loader.svg';
import StateContainer from 'components/StateContainer';
import { useSelector } from 'react-redux';
import { getLoanOffer } from 'selectors/getLoanOfferData';
import { getApplicationData } from 'selectors/getApplicationData';
import { getApplicationData as getApplicationDataThunk } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { RoutePath } from 'enums/Routes';
import { ErrorType } from 'components/Error/ErrorType';
import { FlowComponentType } from 'routes/FlowRouter';

import styles from './Loader.module.scss';

const Loader = ({ handleNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigate = useNavigate();

  const offer = useSelector(getLoanOffer);
  const { application, isLoading: isLoadingApplicaiton } = useSelector(getApplicationData);

  useEffect(() => {
    const run = async () => {
      if (
        offer.isCompleted &&
        offer.response.data.application_id &&
        !isLoadingApplicaiton &&
        application === undefined
      ) {
        const app = await dispatchWithUnwrap(getApplicationDataThunk(offer.response.data.application_id!));
        handleNext(app.application.hardOffer !== undefined);
      }
      if (!offer.isLoading && offer.isError) {
        navigate(RoutePath.Error, { state: { type: ErrorType.CantGenerateOffer } });
      }
    };
    run();
  }, [offer]);

  return (
    <div className={styles.container}>
      <StateContainer icon={<LoadingSpinner />} title="We’re analyzing your finances..." />
    </div>
  );
};

export default Loader;
